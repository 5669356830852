export default {
  name: 'BillingInfo',
  props: {
    billing: {
      type: Object,
      require: true,
    },
  },
  computed: {
    supportEmail () {
      return window.whiteLabelInfo.support_email
    },
  },
}
